import { LeagueGroupFragment } from '@amf/shared/types/graphql';
import Reacket from 'reacket';
import React, { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useTranslate } from '@tolgee/react';
import { isNumeric } from '@amf/shared/utils/string';
import usePlayOff from '@amf/shared/data/hooks/usePlayOff';
import useMatchPlayOff from '@amf/shared/components/match/hooks/useMatchPlayOff';

interface MatchPlayOffProps {
  group: LeagueGroupFragment;
  prefixes: { match: string };
  numberOfMutualMatches: number;
  title?: boolean;
}

function MatchPlayOff({ group, prefixes, title, numberOfMutualMatches }: MatchPlayOffProps) {
  const { t } = useTranslate();
  const { push } = useRouter();
  const playOff = usePlayOff(group.matches, numberOfMutualMatches);
  const { matches } = useMatchPlayOff(playOff);

  const presentMatch = useCallback(
    async (event: MouseEvent) => {
      const el = event.currentTarget as HTMLElement;
      const id = el.getAttribute('data-match-id');
      if (!id) return;

      await push(`${prefixes.match}/${id}`);
    },
    [prefixes, push],
  );

  useEffect(() => {
    if (typeof window === 'undefined') return;

    document.querySelectorAll<HTMLDivElement>('.reacket-player').forEach(player => {
      const matchIdEl = player.parentElement.parentElement.querySelector('.reacket-match-id');
      const matchId = matchIdEl.textContent;
      const match = matches.find(m => m.id === Number(matchId));
      if (!match) return;

      const isPlaceholder = match.matches.filter(m => m.placeholder).length > 0;
      const matchEl = matchIdEl.parentElement;
      if (isPlaceholder) {
        matchEl.classList.add('match-placeholder');
      } else {
        matchEl.classList.remove('match-placeholder');
      }

      const teamId = player.querySelector('.reacket-player-seed')?.textContent;
      if (!teamId) return;

      player.querySelectorAll('.reacket-player-score').forEach(s => s.remove());
      match.matches.forEach(match => {
        let score = match.scoreHome;
        if (match.visiting?.team.idNumber === Number(teamId)) {
          score = match.scoreVisiting;
        }

        const s = score.replace(' ', ' <small>');

        const element = document.createElement('div');
        element.classList.add('reacket-player-score');
        element.setAttribute('data-match-id', match.id);
        element.innerHTML = s.includes('<small>') ? s + '</small>' : s;
        if (score === '-') element.classList.add('inactive');
        player.append(element);
      });

      const team = group.teams.find(t => t.idNumber === Number(teamId));
      const cleanTeamId = team?.id.split('|')[0];
      if (team && group.winnerTeamId === cleanTeamId) {
        player.querySelector('.reacket-player-name').classList.add('player-winner');
        player
          .querySelectorAll('.reacket-player-score')
          .forEach(s => s.classList.add('player-winner'));
      } else {
        player.querySelector('.reacket-player-name').classList.remove('player-winner');
        player
          .querySelectorAll('.reacket-player-score')
          .forEach(s => s.classList.remove('player-winner'));
      }
    });

    const scores = document.querySelectorAll<HTMLDivElement>('[data-match-id]');
    scores.forEach(p => p.addEventListener('click', presentMatch));

    document
      .querySelectorAll('.reacket-player-score,.reacket-player')
      .forEach(s => s.removeAttribute('title'));

    document.querySelectorAll('.reacket-round-header').forEach(h => {
      const segments = h.textContent.split(' ');
      if (segments.length > 1 && isNumeric(segments[1])) {
        const playOffRound = playOff.length - Number(segments[1]) + 1;
        h.textContent = t(`league.playoff.round.${playOffRound}`);
      } else {
        h.textContent = t(h.textContent);
      }
    });

    return () => {
      scores.forEach(p => p.removeEventListener('click', presentMatch));
    };
  }, [group, matches, playOff.length, presentMatch, t]);

  return (
    <div className='MatchPlayOff__outer'>
      {title && <h3>{group.name}</h3>}
      <div className='MatchPlayOff'>
        <div className='MatchPlayOff__content'>
          <Reacket matches={matches} />
        </div>
        <div className='MatchHeaderAlt__teamCrosses'>
          <div className='MatchHeaderAlt__teamCross' />
          <div className='MatchHeaderAlt__teamCross' />
          <div className='MatchHeaderAlt__teamCross' />
          <div className='MatchHeaderAlt__teamCross' />
          <div className='MatchHeaderAlt__teamCross' />
        </div>
      </div>
    </div>
  );
}

export default MatchPlayOff;
