export enum LeaguePhaseType {
  table = 'basic',
  playOff = 'playoff',
  advancedPlayOff = 'advancedPlayoff',
  custom = 'custom',
}

export enum LeagueTeamHomeOrAway {
  all = '',
  home = 'home',
  away = 'away',
}

export enum LeagueAdvancedStatsPlayerMetric {
  mostGoalsPlayer = 'mostGoalsPlayer',
  mostAssistsPlayer = 'mostAssistsPlayer',
  mostProductivePlayer = 'mostProductivePlayer',
  mostYellowCardsPlayer = 'mostYellowCardsPlayer',
  mostRedCardsPlayer = 'mostRedCardsPlayer',
  mostMatchesPlayer = 'mostMatchesPlayer',
  mostGoalsPlayerInOneMatch = 'mostGoalsPlayerInOneMatch',
  mostAssistsPlayerInOneMatch = 'mostAssistsPlayerInOneMatch',
  mostPointsPlayerInOneMatch = 'mostPointsPlayerInOneMatch',
}

export enum LeagueAdvancedStatsTeamMetric {
  mostGoalsTeam = 'mostGoalsTeam',
  leastReceivedGoalsTeam = 'leastReceivedGoalsTeam',
  mostReceivedGoalsTeam = 'mostReceivedGoalsTeam',
  mostYellowCardsTeam = 'mostYellowCardsTeam',
  mostRedCardsTeam = 'mostRedCardsTeam',
  mostYoungTeam = 'mostYoungTeam',
  mostOldTeam = 'mostOldTeam',
}

export enum LeagueLabelColor {
  CHAMPION = 'champion',
  GREEN = 'green',
  YELLOW = 'yellow',
  ORANGE = 'orange',
  RED = 'red',
  GRAY = 'gray',
}

export function getAdvancedStatsPlayerMetric(
  metric: string,
): LeagueAdvancedStatsPlayerMetric | null {
  if (
    Object.values(LeagueAdvancedStatsPlayerMetric).includes(
      metric as LeagueAdvancedStatsPlayerMetric,
    )
  ) {
    return metric as LeagueAdvancedStatsPlayerMetric;
  }

  return null;
}

export function getAdvancedStatsTeamMetric(metric: string): LeagueAdvancedStatsTeamMetric | null {
  if (
    Object.values(LeagueAdvancedStatsTeamMetric).includes(metric as LeagueAdvancedStatsTeamMetric)
  ) {
    return metric as LeagueAdvancedStatsTeamMetric;
  }

  return null;
}
