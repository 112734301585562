import { forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';
import { useTranslate } from '@tolgee/react';
import { generateHtmlId } from '@amf/shared/utils/id';
import { IconChevronDown } from '@amf/shared/icons/IconChevronDown';

interface SelectFieldProps extends InputHTMLAttributes<HTMLSelectElement> {
  label: string;
  error?: string;
  dark?: boolean;
  children?: ReactNode;
}

// eslint-disable-next-line react/display-name
const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>(
  ({ label, error, dark, className, placeholder, children, ...props }, ref) => {
    const { t } = useTranslate();
    const id = generateHtmlId();
    return (
      <div
        className={classNames(
          'FormField',
          {
            'FormField--dark': dark,
            'FormField--withError': error,
          },
          className,
        )}
      >
        <select
          {...props}
          id={id}
          ref={ref}
          className='FormField__input'
          placeholder={placeholder ?? ' '}
        >
          {children}
        </select>
        <label htmlFor={id} className='FormField__label'>
          {label}
        </label>
        <div className='FormField__accessory'>
          <IconChevronDown className='FormField__accessoryIcon' />
        </div>
        {error && <p className='FormField__error'>{t(error)}</p>}
      </div>
    );
  },
);

export default SelectField;
