import { ReactNode } from 'react';
import classNames from 'classnames';

interface LoaderProps {
  children?: ReactNode;
  size?: 'normal' | 'small';
}

export default function Loader({ children, size }: LoaderProps) {
  return (
    <div
      className={classNames('Loader__container', {
        'Loader__container--small': size === 'small',
      })}
    >
      <div className='Loader' />
      {children && <p className='Loader__text'>{children}</p>}
    </div>
  );
}
