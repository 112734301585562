import { LeagueGroupFragment } from '@amf/shared/types/graphql';
import MatchPlayOff from '@amf/shared/components/match/MatchPlayOff';

interface MatchPlayOffTableProps {
  groups: LeagueGroupFragment[];
  prefixes: { match: string };
  numberOfMutualMatches: number;
}

function MatchPlayOffTable({ groups, prefixes, numberOfMutualMatches }: MatchPlayOffTableProps) {
  return (
    <div className='MatchPlayOffTable'>
      {groups.map(group => (
        <MatchPlayOff
          key={group.id}
          group={group}
          prefixes={prefixes}
          numberOfMutualMatches={numberOfMutualMatches}
          title={groups.length > 1}
        />
      ))}
    </div>
  );
}

export default MatchPlayOffTable;
