import { useMemo } from 'react';
import { PlayOffRound } from '@amf/shared/data/hooks/usePlayOff';
import { MatchListItemFragment } from '@amf/shared/types/graphql';

export type MatchTeam = {
  id: number;
  name: string;
  seed: number;
};

export type Match = {
  id: number;
  round: number;
  match: number;
  players: MatchTeam[];
  score: number[];
  matches: MatchListItemFragment[];
};

function useMatchPlayOff(rounds: PlayOffRound[]) {
  const matches = useMemo<Match[]>(() => {
    let result: Match[] = [];

    for (const { round, matchRounds: matches } of rounds) {
      for (const { id, teams, matches: raw } of matches) {
        const players = teams.map<MatchTeam>(team => {
          const id = team?.idNumber ?? Math.floor(Math.random() * -1000 + 1);
          return {
            id,
            seed: id,
            name: team?.name ?? '?',
          };
        });

        result.push({
          id,
          match: 1,
          players,
          score: [-1, -1],
          round,
          matches: raw,
        });
      }
    }

    return result;
  }, [rounds]);

  return { matches };
}

export default useMatchPlayOff;
