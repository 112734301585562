export const REGEX_PHONE_NUMBER = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;

export const truncate = (text: string, maxLength = 40): string => {
  if (!text) return '';
  if (text.length <= maxLength) return text;
  return `${text.substring(0, maxLength)}...`;
};

export function getInitials(givenName: string, familyName: string) {
  return `${givenName.substring(0, 1)}${familyName.substring(0, 1)}`;
}

export function isNumeric(str: unknown) {
  if (typeof str != 'string') return false;
  return !isNaN(Number(str)) && !isNaN(parseFloat(str));
}

export function stringifyHtml(html: string): string {
  return html
    .replace(/<br>/gi, '\n') // New lines
    .replace(/<p.*>/gi, '\n') // Paragraphs
    .replace(/<a.*href="(.*?)".*>(.*?)<\/a>/gi, ' $2 (Odkaz->$1) ') // Links fallback
    .replace(/<(?:.|\s)*?>/g, ''); // Other html tags
}
