import { TeamListItemFragment, TeamRetributionItemFragment } from '@amf/shared/types/graphql';
import { useTranslate } from '@tolgee/react';

interface TeamRetributionProps {
  team: TeamListItemFragment;
  retribution: TeamRetributionItemFragment;
}

function TeamRetribution({
  team: { name },
  retribution: { points, description },
}: TeamRetributionProps) {
  const { t } = useTranslate();
  return (
    <>
      {points === 1 &&
        t('team.retribution', {
          team: name,
          points: -points,
          reason: description,
        })}
      {points > 1 &&
        points <= 4 &&
        t('team.retribution.two', {
          team: name,
          points: -points,
          reason: description,
        })}
      {points > 4 &&
        t('team.retribution.more', {
          team: name,
          points: -points,
          reason: description,
        })}
    </>
  );
}

export default TeamRetribution;
