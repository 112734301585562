let lastId = 0;

export function generateHtmlId(prefix?: string) {
  // eslint-disable-next-line no-param-reassign
  prefix = prefix || '__id';
  // eslint-disable-next-line no-plusplus
  lastId++;
  return `${prefix}${lastId}`;
}

export function withGeneratedId(callback: (id: string) => React.ReactNode) {
  return callback(generateHtmlId());
}
