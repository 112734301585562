import { useMemo } from 'react';
import { useMatchRounds } from '@amf/shared/data/Match';
import { MatchListItemFragment, TeamListItemFragment } from '@amf/shared/types/graphql';

export type PlayOffMatchRound = {
  id: number;
  round: number;
  playOffMatcher: string | null;
  teams: TeamListItemFragment[] | null[];
  matches: MatchListItemFragment[];
};

export type PlayOffRound = {
  round: number;
  matchRounds: PlayOffMatchRound[];
};

function isEmptyTeamArray(teams: TeamListItemFragment[] | null[]): boolean {
  for (const team of teams) {
    if (team !== null) {
      return false;
    }
  }
  return true;
}

function findRound(
  teams: TeamListItemFragment[] | null[],
  playOffMatcher: string | null,
  round: number,
  rounds: PlayOffMatchRound[],
  numberOfMutualMatches: number,
): PlayOffMatchRound | null {
  for (const matchRound of rounds) {
    if (matchRound.round !== round) continue;

    let found = true;
    for (const team of teams) {
      if (!matchRound.teams.map(t => t?.id ?? null).includes(team?.id ?? null)) {
        found = false;
        break;
      }
    }

    if (
      matchRound.matches.length >= numberOfMutualMatches ||
      matchRound.playOffMatcher !== playOffMatcher
    ) {
      continue;
    }

    if (found) return matchRound;
  }

  return null;
}

function usePlayOff(
  matches: MatchListItemFragment[],
  numberOfMutualMatches: number,
): PlayOffRound[] {
  const rounds = useMatchRounds(matches);
  return useMemo(() => {
    const playOff: PlayOffRound[] = [];
    for (const { round, matches } of rounds) {
      const playOffRound: PlayOffRound = { round, matchRounds: [] };
      for (const match of matches) {
        const home = match.home?.team ?? null;
        const visiting = match.visiting?.team ?? null;

        const matchRound = findRound(
          [home, visiting],
          match.playOffMatcher ?? null,
          round,
          playOffRound.matchRounds,
          numberOfMutualMatches,
        );
        if (matchRound) {
          matchRound.matches.push(match);
        } else {
          const matchRound: PlayOffMatchRound = {
            id: Math.floor(Math.random() * 1000 + 1),
            round,
            matches: [match],
            teams: [home, visiting],
            playOffMatcher: match.playOffMatcher ?? null,
          };

          playOffRound.matchRounds.push(matchRound);
          playOffRound.matchRounds = playOffRound.matchRounds.sort((a, b) => {
            if (b.playOffMatcher === 'Finále') return 1;
            if (b.playOffMatcher === 'Zápas o třetí místo') return -1;
            return 0;
          });
        }
      }

      playOff.push(playOffRound);
    }

    return playOff;
  }, [numberOfMutualMatches, rounds]);
}

export default usePlayOff;
