/* eslint-disable @next/next/no-img-element */
import { TeamListItemFragment } from '@amf/shared/types/graphql';
import classNames from 'classnames';

interface TeamAvatarProps {
  team: TeamListItemFragment;
  className?: string;
}

function TeamAvatar({ team, className }: TeamAvatarProps) {
  return (
    <div className={classNames('TeamAvatar', className)}>
      {team.club.brandUrl && <img src={team.club.brandUrl} alt={team.name} />}
      {!team.club.brandUrl && <span>{team.name.substr(0, 1)}</span>}
    </div>
  );
}

export default TeamAvatar;
